import React, { Component } from 'react'
import axios from 'axios'
import * as PropTypes from 'prop-types'
import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
} from 'antd'

import { postsService } from '../../redux/services'

import { UIDrawerForm, UIRichTextEditor } from '../../components'
import { PatternFormat } from 'react-number-format'
import moment from 'moment'
import UIUpload from 'components/Upload'
import { API_URL } from 'config/general'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

class Edit extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onComplete: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isSending: false,
            id: 0,
            documentType: 'cpf',
        }

        this._axiosCancelToken = null
    }

    onOpen = (id) => {
        this.setState({
            isLoading: true,
            id: id,
        })

        postsService
            .show({ id })
            .then((response) => {
                let item = response.data

                this.setState(
                    {
                        isLoading: false,
                    },
                    () => {
                        // Fill form
                        this.fillForm(item)
                    }
                )
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                    onOk: () => {
                        // Force close
                        return this.onClose()
                    },
                })
            })
    }

    fillForm = (data) => {
        if (data.document && data.document.length > 15) {
            this.setState({ documentType: 'cnpj' })
        }

        this.form.setFieldsValue({
            title: data.title,
            description: data.description,
            category: data.category,
            is_active: data.is_active,
        })

        this.content.setValue(data.content)
        this.upload.setFiles([
            {
                uuid: 1,
                url: API_URL + "/site/posts/images/" + data.image_url
            }
        ])
    }

    resetFields = () => {
        this.form.resetFields()
    }

    onClose = () => {
        // Reset fields
        this.resetFields()

        // Callback
        this.props.onClose()
    }

    onFinish = (values) => {
        const { id } = this.state

        const file = this.upload.getFiles();

        if (file.hasError) {
            Modal.error({
                title: "Ocorreu um erro!",
                content: file.error,
            });

            return false;
        }

        this.setState({
            isSending: true,
        })

        const data = { ...values }

        // File
        if (file.files.length) {
            if (!file.files[0].uuid) {
                data.file = file.files[0];
            }
        }

        // id
        data.id = id

        postsService
            .edit(data)
            .then((response) => {
                this.setState({
                    isSending: false,
                })

                // Reset fields
                this.resetFields()

                // Success message
                message.success('Registro atualizado com sucesso.')

                // Callback
                this.props.onComplete()
            })
            .catch((data) => {
                this.setState({
                    isSending: false,
                })

                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    render() {
        const { visible } = this.props

        const { id, isLoading, isSending, documentType } = this.state

        const documentMask =
            documentType === 'cpf' ? '###.###.###-##' : '##.###.###/####-##'

        return (
            <UIDrawerForm
                visible={visible}
                width={500}
                onClose={this.onClose}
                isLoading={isLoading}
                isSending={isSending}
                formId={formId}
                title="Editar post"
            >
                <Form
                    ref={(el) => (this.form = el)}
                    id={formId}
                    layout="vertical"
                    scrollToFirstError
                    onFinish={this.onFinish}
                >
                    <Col xs={24} sm={12}>
                        <UIUpload
                            ref={el => this.upload = el}
                            label="Imagem"
                            labelError="Imagem"
                            acceptedFiles={['jpg', 'jpeg', 'png']}
                            maxFileSize={4}
                            minFiles={1}
                        />
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="title"
                            label="Título"
                            hasFeedback
                            rules={[
                                { required: true, message: 'Campo obrigatório.' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="description"
                            label="Descrição"
                            hasFeedback
                            rules={[
                                { required: true, message: 'Campo obrigatório.' },
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                    <UIRichTextEditor
                        ref={el => this.content = el}
                        name="content"
                        label="Texto"
                    />
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="category"
                            label="Categoria"
                            hasFeedback
                            rules={[
                                { required: true, message: 'Campo obrigatório.' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Divider />
                    <Form.Item
                        name="is_active"
                        label="Ativo"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </UIDrawerForm>
        )
    }
}

export default Edit
