import React from 'react'
import IMask from 'imask'
import { Input } from 'antd'

class UIInputMask extends React.Component {

    componentDidMount() {
        this.mask = IMask(this.input.input, {
            mask: this.props.mask,
        })
    }

    componentWillUnmount() {
        this.mask.destroy()
    }

    render() {
        return (
            <Input.Search
                ref={(input) => {
                    this.input = input
                }}
                {...this.props}
            />
        )
    }
}

export default UIInputMask
