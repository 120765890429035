import { Empty, Modal, Skeleton } from 'antd'
import { UILayout } from 'components'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { Component } from 'react'
import { trainingUserService } from 'redux/services'
import { trainingService } from 'redux/services'

const config = {
    title: 'Treinamentos',
    permissionPrefix: 'training',
    list: 'training',
    searchPlaceholder: 'Buscar por nome',
    orders: [
        {
            label: 'Mais recentes',
            field: 'id',
            sort: 'desc',
            default: true,
        },
        {
            label: 'Mais antigos',
            field: 'id',
            sort: 'asc',
        },
    ],
}

class TrainingUser extends Component {
    constructor(props) {
        super(props)

        const defaultOrder = config.orders.find((o) => o.default)

        this.state = {
            isLoading: false,
            listType: 'list',
            data: [],
            pagination: {
                current: 1,
                pageSize: 100,
                total: 0,
            },
            orderByLabel: defaultOrder.label,
            orderByField: defaultOrder.field,
            orderBySort: defaultOrder.sort,
            search: '',
            // Actions
            createModalVisible: false,
            showModalVisible: false,
            exportModalVisible: false,
            filtersModalVisible: false,
            activeLoadings: [],
            isExporting: false,
            // Filters
            totalFilters: 0,
            filters: {
                is_active: null,
                created_at: null,
            },
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.listType && state.listType !== props.listType) {
            return {
                listType: props.listType,
            }
        }

        return null
    }

    componentDidMount() {
        // Fecth all
        this.fetchGetAll(true)
    }

    fetchGetAll = (init = false, exportItems = false) => {
        const { pagination, orderByField, orderBySort, search, filters } =
            this.state

        if (exportItems) {
            this.setState({
                isExporting: true,
            })
        } else {
            this.setState({
                isLoading: true,
            })
        }

        const data = {
            orderBy: `${orderByField}:${orderBySort}`,
            search: search,
        }

        if (exportItems) {
            data.exportItems = true
        } else {
            data.page = init ? 1 : pagination.current
            data.limit = pagination.pageSize
        }

        data.is_active = true

        if (filters.created_at) {
            data.created_at = [
                filters.created_at[0]
                    .clone()
                    .startOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
                filters.created_at[1]
                    .clone()
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
            ]
        }

        trainingService
            .getAll(data)
            .then((response) => {
                if (exportItems) {
                    this.setState({
                        isExporting: false,
                    })

                    window.open(response.data.file_url, '_blank')
                } else {
                    this.setState((state) => ({
                        isLoading: false,
                        data: response.data.data,
                        pagination: {
                            ...state.pagination,
                            current: response.data.meta.current_page,
                            total: response.data.meta.total,
                        },
                    }))
                }
            })
            .catch((data) => {
                Modal.error({
                    title: 'Ocorreu um erro!',
                    content: String(data),
                })
            })
    }

    onClickTraining = (course) => {
        if (!course?.classes?.length) {
            Modal.error({
                title: 'Aviso!',
                content: 'Este treinamento não possui aulas.',
            })
            return
        }

        this.props.router.navigate(`/watching/${course.id}`)
    }

    render() {
        const { data, isLoading } = this.state

        return (
            <UILayout>
                <DashboardNavbar absolute isMini title={config.title} />
                <div className="page training-users-component">
                    {isLoading ? (
                        <Skeleton />
                    ) : !!data.length ? (
                        <div className="courses-grid">
                            {data.map((course, index) => (
                                <div key={index} className="course-card" onClick={() => this.onClickTraining(course)}>
                                    <i className="fad fa-users-class"></i>
                                    <h3 className="course-name">{course.name}</h3>
                                    <p className="course-lessons">{course.classes?.length} aulas</p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Empty />
                    )}
                </div>
            </UILayout>
        )
    }
}

export default TrainingUser