import React, { Component } from "react";
import { connect } from "react-redux";
import { BackTop, Drawer, Layout, Menu } from "antd";
import QueueAnim from "rc-queue-anim";
import enquire from "enquire.js";
import { NavLink, withRouter } from "react-router-dom";

const { Content, Header, Sider } = Layout;

import {
    generalActions,
} from "./../redux/actions";

import MainNavigation from "./../navigations/mainNavigation";

class DefaultTemplate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            siderBarDrawer: false,
        };

        this.mdSideBarOn = "screen and (max-width: 1199px)";
        this.mdSideBarOff = "screen and (min-width: 1200px)";
    }

    componentDidMount() {
        enquire.register(this.mdSideBarOn, () => {
            this.setState({
                siderBarDrawer: true,
            })
        });

        enquire.register(this.mdSideBarOff, () => {
            this.setState({
                siderBarDrawer: false,
            })
        });
    };

    componentWillUnmount() {
        enquire.unregister(this.mdSideBarOn);
        enquire.unregister(this.mdSideBarOff);
    };

    toggle = () => {
        this.props.siderToggle(!this.props.siderCollapsed);
    };

    render() {

        const { siderBarDrawer } = this.state;
        const { siderCollapsed, showSideNav } = this.props;

        const siderWidth = siderBarDrawer ? 0 : (siderCollapsed ? 80 : 220);

        return (
            <Layout className="template-default">
                {/* {siderBarDrawer ? (
                    <Drawer
                        width="95vw"
                        placement="left"
                        closable={false}
                        onClose={this.toggle}
                        open={!siderCollapsed}
                        className="template-default-ant-drawer site-menu">
                        <div className="logo">
                            <img src={require("../assets/images/logo-ct.png")} alt="" />
                        </div>
                        <MainNavigation
                            // onClick={this.toggle}
                            router={this.props.router}
                        />
                    </Drawer>
                ) : (
                    <Sider
                        theme="dark"
                        trigger={null}
                        collapsible={false}
                        collapsed={false}
                        breakpoint="lg"
                        width={256}
                        className="site-menu">
                        <div className="logo" >
                            <img src={require("../assets/images/logo-ct.png")} alt="" />
                        </div>
                        <MainNavigation
                            router={this.props.router}
                        />
                    </Sider>
                )} */}
                {showSideNav === false ? (
                    <></>
                ) : (
                    <Sider
                        theme="dark"
                        trigger={null}
                        collapsible={false}
                        collapsed={false}
                        breakpoint="lg"
                        width={256}
                        className="site-menu">
                        <div className="logo" >
                            <img src={require("../assets/images/logo-ct.png")} alt="" />
                        </div>
                        <MainNavigation
                            router={this.props.router}
                        />
                    </Sider>
                )}
                <Layout style={{ paddingLeft: showSideNav === false ? 0 : 256 }}>
                    <Content className="site-content">
                        {this.props.children}
                    </Content>
                    <BackTop />
                </Layout>
            </Layout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        siderCollapsed: state.general.siderCollapsed,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        siderToggle: (collapsed) => {
            dispatch(generalActions.siderToggle(collapsed));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTemplate);
